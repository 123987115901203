<template>
  <svg :width="width" viewBox="0 0 48 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M44.1137 2.42188H4.00749C2.98202 2.42188 2.15072 3.50619 2.15072 4.84375V57.1562C2.15072 58.4938 2.98202 59.5781 4.00749 59.5781H44.1137C45.1392 59.5781 45.9705 58.4938 45.9705 57.1562V4.84375C45.9705 3.50619 45.1392 2.42188 44.1137 2.42188ZM4.00749 0C1.95655 0 0.293945 2.16862 0.293945 4.84375V57.1562C0.293945 59.8314 1.95655 62 4.00749 62H44.1137C46.1647 62 47.8273 59.8314 47.8273 57.1562V4.84375C47.8273 2.16862 46.1647 0 44.1137 0H4.00749Z"
      :fill="fillColor"
    />
    <rect x="14.7607" y="37.2002" width="18.6" height="8.26667" rx="1" :fill="fillColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.5482 38.8534H16.573C16.4774 38.8534 16.3999 38.9327 16.3999 39.0305V43.6363C16.3999 43.7341 16.4774 43.8134 16.573 43.8134H31.5482C31.6438 43.8134 31.7213 43.7341 31.7213 43.6363V39.0305C31.7213 38.9327 31.6438 38.8534 31.5482 38.8534ZM16.573 38.2334C16.1427 38.2334 15.7939 38.5903 15.7939 39.0305V43.6363C15.7939 44.0765 16.1427 44.4334 16.573 44.4334H31.5482C31.9785 44.4334 32.3273 44.0765 32.3273 43.6363V39.0305C32.3273 38.5903 31.9785 38.2334 31.5482 38.2334H16.573Z"
      fill="white"
    />
    <circle cx="20.9606" cy="48.5665" r="1.03333" :fill="fillColor" />
    <ellipse cx="27.1608" cy="48.5665" rx="1.03333" ry="1.03333" :fill="fillColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.6265 13.4336H7.86179C7.10767 13.4336 6.49414 14.0447 6.49414 14.7959V19.791C6.49414 20.5422 7.10767 21.1533 7.86179 21.1533H10.3843V27.5713C10.3843 28.3225 10.9979 28.9336 11.752 28.9336H16.7363C17.4904 28.9336 18.1039 28.3225 18.1039 27.5713V21.1533H20.6265C21.3806 21.1533 21.9941 20.5422 21.9941 19.791V14.7959C21.9941 14.0447 21.3806 13.4336 20.6265 13.4336ZM11.752 28.0254C11.5006 28.0254 11.2961 27.8217 11.2961 27.5713L11.2961 25.6092V16.3096H10.3843V20.2451H7.86179C7.61041 20.2451 7.40591 20.0414 7.40591 19.791V14.7959C7.40591 14.5455 7.61041 14.3418 7.86179 14.3418H20.6265C20.8779 14.3418 21.0824 14.5455 21.0824 14.7959V19.791C21.0824 20.0414 20.8779 20.2451 20.6265 20.2451H18.1039V16.3096H17.1922L17.1922 25.6092V27.5713C17.1922 27.8217 16.9877 28.0254 16.7363 28.0254H12.7691H11.752Z"
      :fill="fillColor"
    />
    <rect x="8.4165" y="15.4395" width="11.7164" height="0.911761" rx="0.45588" :fill="fillColor" />
    <path
      d="M14.2443 23.0908C15.3001 23.0908 16.159 22.2352 16.159 21.1836C16.159 20.132 15.3001 19.2764 14.2443 19.2764C13.1885 19.2764 12.3296 20.132 12.3296 21.1836C12.3296 22.2352 13.1885 23.0908 14.2443 23.0908ZM14.2443 20.1846C14.7973 20.1846 15.2472 20.6327 15.2472 21.1836C15.2472 21.7345 14.7973 22.1826 14.2443 22.1826C13.6913 22.1826 13.2414 21.7345 13.2414 21.1836C13.2414 20.6327 13.6913 20.1846 14.2443 20.1846Z"
      :fill="fillColor"
    />
    <path
      d="M14.4975 24.681C14.2612 24.5261 13.9608 24.6193 13.8422 24.8448C13.6635 25.193 13.9582 25.5739 14.3333 25.5039C14.7344 25.4206 14.8314 24.9059 14.4975 24.681Z"
      :fill="fillColor"
    />
    <path
      d="M13.7969 17.3973C13.8474 17.6397 14.0778 17.8015 14.333 17.7539C14.5802 17.7026 14.7381 17.4705 14.691 17.2199C14.6336 16.947 14.3609 16.7985 14.1117 16.8741C13.8784 16.9483 13.7542 17.1699 13.7969 17.3973Z"
      :fill="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40.3358 14.3378H27.4191C27.2051 14.3378 27.0316 14.5113 27.0316 14.7253V27.6419C27.0316 27.8559 27.2051 28.0294 27.4191 28.0294H40.3358C40.5498 28.0294 40.7233 27.8559 40.7233 27.6419V14.7253C40.7233 14.5112 40.5498 14.3378 40.3358 14.3378ZM27.4191 13.4336C26.7057 13.4336 26.1274 14.0119 26.1274 14.7253V27.6419C26.1274 28.3553 26.7057 28.9336 27.4191 28.9336H40.3358C41.0491 28.9336 41.6274 28.3553 41.6274 27.6419V14.7253C41.6274 14.0119 41.0491 13.4336 40.3358 13.4336H27.4191Z"
      :fill="posColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M39.0441 14.0794H28.7108V19.8919C28.7108 20.2486 28.9999 20.5378 29.3566 20.5378H38.3983C38.755 20.5378 39.0441 20.2486 39.0441 19.8919V14.0794ZM28.0649 13.4336V19.8919C28.0649 20.6053 28.6432 21.1836 29.3566 21.1836H38.3983C39.1116 21.1836 39.6899 20.6053 39.6899 19.8919V13.4336H28.0649Z"
      :fill="posColor"
    />
    <path
      d="M30.3556 19.3906H37.4194C37.5131 19.3906 37.6029 19.3534 37.6692 19.2872C37.7354 19.2209 37.7726 19.1311 37.7726 19.0374V15.5055C37.7726 15.4119 37.7354 15.322 37.6692 15.2558C37.6029 15.1896 37.5131 15.1523 37.4194 15.1523H30.3556C30.262 15.1523 30.1721 15.1896 30.1059 15.2558C30.0397 15.322 30.0024 15.4119 30.0024 15.5055V19.0374C30.0024 19.1311 30.0397 19.2209 30.1059 19.2872C30.1721 19.3534 30.262 19.3906 30.3556 19.3906ZM30.7088 15.8587H37.0662V18.6842H30.7088V15.8587Z"
      :fill="posColor"
    />
    <path
      d="M32.1569 24.0051C32.1186 24.0313 32.0858 24.0648 32.0605 24.1037C32.0351 24.1426 32.0177 24.1861 32.0091 24.2317C32.0006 24.2774 32.0012 24.3242 32.0108 24.3696C32.0204 24.4151 32.0388 24.4581 32.0651 24.4964C32.1061 24.5548 32.1272 24.625 32.125 24.6964C32.1229 24.7678 32.0976 24.8365 32.0531 24.8923C31.9955 24.9662 31.9697 25.06 31.9812 25.153C31.9928 25.2459 32.0408 25.3305 32.1147 25.388C32.1886 25.4456 32.2823 25.4714 32.3753 25.4599C32.4683 25.4483 32.5528 25.4003 32.6104 25.3264C32.7467 25.1522 32.8238 24.939 32.8306 24.7178C32.8373 24.4967 32.7733 24.2792 32.6478 24.097C32.5949 24.0197 32.5134 23.9666 32.4214 23.9494C32.3293 23.9322 32.2342 23.9522 32.1569 24.0051Z"
      :fill="posColor"
    />
    <path
      d="M33.5313 23.5142C33.5062 23.4742 33.4734 23.4396 33.4346 23.4125C33.3959 23.3854 33.3522 23.3663 33.306 23.3565C33.2598 23.3466 33.212 23.346 33.1656 23.3549C33.1192 23.3638 33.0751 23.3819 33.0358 23.4082C32.9965 23.4345 32.9629 23.4683 32.9369 23.5078C32.911 23.5473 32.8932 23.5916 32.8847 23.6381C32.8762 23.6845 32.877 23.7323 32.8873 23.7784C32.8975 23.8245 32.9169 23.8681 32.9443 23.9066C33.1066 24.1495 33.1894 24.4367 33.1813 24.7287C33.1731 25.0207 33.0744 25.3029 32.8988 25.5362C32.8709 25.5733 32.8507 25.6156 32.8392 25.6605C32.8277 25.7054 32.8251 25.7522 32.8317 25.7981C32.8382 25.844 32.8538 25.8882 32.8774 25.9281C32.901 25.968 32.9323 26.0029 32.9694 26.0307C33.0065 26.0585 33.0487 26.0788 33.0937 26.0903C33.1386 26.1018 33.1853 26.1044 33.2313 26.0978C33.2772 26.0912 33.3214 26.0757 33.3613 26.0521C33.4012 26.0284 33.436 25.9972 33.4639 25.9601C33.727 25.6096 33.8748 25.186 33.8869 24.7479C33.899 24.3098 33.7747 23.8787 33.5313 23.5142Z"
      :fill="posColor"
    />
    <path
      d="M34.415 22.9243C34.3893 22.8857 34.3563 22.8525 34.3178 22.8266C34.2793 22.8008 34.2361 22.7827 34.1906 22.7736C34.1451 22.7644 34.0983 22.7643 34.0528 22.7732C34.0073 22.7821 33.9639 22.8 33.9253 22.8256C33.8867 22.8513 33.8535 22.8843 33.8276 22.9228C33.8017 22.9613 33.7837 23.0046 33.7745 23.05C33.7654 23.0955 33.7652 23.1423 33.7742 23.1879C33.7831 23.2334 33.8009 23.2767 33.8266 23.3153C34.1095 23.7415 34.2537 24.2448 34.2393 24.7562C34.225 25.2675 34.0528 25.7619 33.7464 26.1716C33.7186 26.2087 33.6983 26.2509 33.6868 26.2958C33.6753 26.3408 33.6728 26.3875 33.6793 26.4334C33.6859 26.4794 33.7014 26.5235 33.7251 26.5634C33.7487 26.6034 33.78 26.6382 33.8171 26.666C33.8542 26.6939 33.8964 26.7141 33.9413 26.7256C33.9862 26.7371 34.033 26.7397 34.0789 26.7331C34.1248 26.7266 34.169 26.711 34.2089 26.6874C34.2488 26.6638 34.2837 26.6325 34.3115 26.5954C34.7055 26.069 34.927 25.4336 34.9455 24.7763C34.964 24.119 34.7787 23.4721 34.415 22.9243Z"
      :fill="posColor"
    />
    <path
      d="M34.806 22.2401C34.7674 22.2658 34.7342 22.2989 34.7084 22.3374C34.6825 22.376 34.6645 22.4192 34.6554 22.4647C34.6463 22.5102 34.6462 22.557 34.6552 22.6026C34.6642 22.6481 34.6821 22.6914 34.7078 22.73C35.1129 23.338 35.3195 24.0566 35.2991 24.7869C35.2787 25.5171 35.0324 26.2231 34.5941 26.8076C34.5662 26.8447 34.546 26.8869 34.5345 26.9318C34.523 26.9768 34.5204 27.0235 34.527 27.0694C34.5335 27.1154 34.5491 27.1595 34.5727 27.1994C34.5963 27.2393 34.6276 27.2742 34.6647 27.302C34.7018 27.3299 34.744 27.3501 34.789 27.3616C34.8339 27.3731 34.8807 27.3757 34.9266 27.3691C34.9725 27.3626 35.0167 27.347 35.0566 27.3234C35.0965 27.2998 35.1314 27.2685 35.1592 27.2314C35.685 26.5299 35.9803 25.6826 36.0044 24.8063C36.0286 23.93 35.7804 23.0677 35.2941 22.3383C35.2422 22.2608 35.1617 22.207 35.0703 22.1886C34.9788 22.1702 34.8838 22.1887 34.806 22.2401Z"
      :fill="posColor"
    />
    <path d="M33.5342 16.918H34.2406V17.6243H33.5342V16.918Z" :fill="fillColor" />
    <path d="M34.9473 16.918H35.6536V17.6243H34.9473V16.918Z" :fill="fillColor" />
    <path d="M32.1216 16.918H32.828V17.6243H32.1216V16.918Z" :fill="fillColor" />
  </svg>
</template>

<script>
export default {
  name: 'OnlineTerminalSVG',

  props: {
    width: {
      type: Number,
      default: 48,
    },
    fillColor: {
      type: String,
      default: 'grey',
    },
    posColor: {
      type: String,
      default: 'grey',
    },
  },
}
</script>
